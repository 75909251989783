import {pl} from "./lang/pl.js";

export const lang={
	is:"pl",
	data:{
		"pl":pl
	},
	noData:{},
	translate:function(key){
		const data=lang.data[lang.is];
		if(data && key){
			if(data[key]){
				return data[key];
			}else{
				if(lang.noData[lang.is]){
					lang.noData[lang.is][key]=key;
				}else{
					lang.noData[lang.is]={};
					lang.noData[lang.is][key]=key;
				}
				return key;
			}
		}else{
			return key;
		}
	}
}

window.lang=lang;