export const pl = {
    "Wyślij formularz": "Uzyskaj darmową propozycję",
    "Zamów kontakt": "Zamów kontakt",
    "Zamów kontakt telefoniczny": "Zamów kontakt telefoniczny",
    "Land": "Region",

    "<div class=\"cell small-12 text-center h4\"><hr>Tralka</div>": "<div class=\"cell small-12 text-center h4\"><hr>Tralka</div>",

    "Dalej": "Dalej",
    "Wstecz": "Wstecz",
    "Przejdź do konfiguracji schodów": "Przejdź do konfiguracji schodów",

    "tralka-standard 4 x 2 cm": "Tralka Standard 4 x 2 cm",
    "tralka-stal nierdzewna": "Tralka ze stali nierdzewnej",
    "tralka-1": "Tralka wzór 1",
    "tralka-2": "Tralka wzór 2",
    "tralka-3": "Tralka wzór 3",
    "tralka-4": "Tralka wzór 4",
    "tralka-5": "Tralka wzór 5",
    "tralka-6": "Tralka wzór 6",
    "tralka-7": "Tralka wzór 7",
    "tralka-8": "Tralka wzór 8",
    "tralka-9": "Tralka wzór 9",
    "tralka-10": "Tralka wzór 10",
    "tralka-11": "Tralka wzór 11",
    "tralka-12": "Tralka wzór 12",
    "tralka-13": "Tralka wzór 13",
    "tralka-14": "Tralka wzór 14",
    "tralka-15": "Tralka wzór 15",
    "tralka-16": "Tralka wzór 16",
    "tralka-17": "Tralka wzór 17",

    'l-1': 'schody proste',
    'l-2': 'schody łamane lewe',
    'l-3': 'schody dwubiegowe lewe',
    'l-4': 'schody zabiegowe 3/4 lewe',
    'l-5': 'schody zabiegowe 1/4 lewe',
    'l-6': 'schody zabiegowe 1/2 lewe',
    'l-7': 'schody trójbiegowe lewe',
    'r-1': 'schody proste',
    'r-2': '1/4 schody łamane prawe',
    'r-3': 'schody dwubiegowe lewe',
    'r-4': 'schody zabiegowe 3/4 prawe',
    'r-5': 'schody zabiegowe 1/4 prawe',
    'r-6': 'schody zabiegowe 1/2 prawe',
    'r-7': 'schody trójbiegowe prawe',

    "#eee": "Biały",
    "#111": "Czarny",
    "#323c4b": "Anthrazyt",
    "Naturalny": "Naturalny",
    "Czarny": "Czarny",
    "Biały": "Biały",
    "Antracyt": "Antracyt",
    "Kolor słupków": "Kolor tralek",
    "Kolor słupków głównych": "Kolor słupków",
    "Kolor policzków": "Kolor policzków",
    "Kolor poręczy": "Kolor poręczy",
    "Kolor stopni": "Kolor stopni",
    "Kolor podstopni": "Kolor podstopni",

    "construction": "Konstrukcja",
    "dir_type": "Rodzaj",
    "type": "Typ",
    "risers": "Podstopnie",
    "block": "Stopień blokowy",
    "balustrade": "Balustrada",
    "wood_type": "Rodzaj drewna",
    "handrail": "Poręcz",
    "baluster": "Tralka",
    "cheeks_color": "Kolor policzków",
    "bars_color": "Kolot tralek",
    "mainbars_color": "Kolor słupków",
    "handrail_color": "Kolor poręczy",
    "steps_color": "Kolor stopni",
    "risers_color": "Kolor podstopni",
    "surface_finish": "Wykończenie powierzchni",

    "porecz-1": "Poręcz wzór 1",
    "porecz-2": "Poręcz wzór 2",
    "porecz-3": "Poręcz wzór 3",
    "porecz-4": "Poręcz wzór 4",
    "porecz-5": "Poręcz wzór 5"

}