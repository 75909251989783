import "../gazda-form/js/contactform.js";

if (window.location.pathname == '/wycena-schodow') {
	$(".valuation-button").css("display","none");
	$(".quick-contact-button").css("bottom","32px");
}
$(window).on("load",function(){
	$(".contact-help").on("click",function(event){
		if(this===event.target){
			$(this).removeClass("active");
		}
	});

	$(".quick-contact-button,.leave-contact,.leave-contact-button").on("click",function(event){
		event.preventDefault();
		$(".contact-help").addClass("active");
		return false;
	})

	$(".leave-contact").on("click",function(){
		$(this).removeClass("active");
	});

	if(location.pathname==="/"){
		// $(".valuation-button").attr("style","-webkit-transform:translateX(150%);");
		$(".quick-contact-button").attr("style","-webkit-transform:translateY(145%);");
	}

	$(window).on("scroll",function(){
		if($(".valuation-slider").length>0){
			const banner=$(".valuation-slider").rect();
			if(banner.top<96){
				$(".valuation-button").attr("style","");
				$(".quick-contact-button").attr("style","");
			}else{
				$(".valuation-button").attr("style","-webkit-transform:translateX(150%);");
				$(".quick-contact-button").attr("style","-webkit-transform:translateY(145%);");
			}
		}
	})
});


